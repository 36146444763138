import { useState } from "react";
import styled, { createGlobalStyle } from "styled-components";
import { Button, PageHeader, Card, Skeleton, Spin } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import moment from 'moment-timezone';
import reduce from 'lodash/reduce';
import { useMrSystem } from "../../../lib/hooks/use-mr-system";
import { Level, Refilling, RefillingReason } from "../../../models/Entities";
import { useMrSystemRefillings } from "../../../lib/hooks/use-mr-system-refillings";
import FormHeliumLevel from "./FormHeliumLevel";
import { HeliumLevelFormValues, RefillingFormValues } from "../../../models/Dtos";
import FormRefilling from "./FormRefilling";

const GlobalStyle = createGlobalStyle`
  .ant-timeline.ant-timeline-label .ant-timeline-item-label {
    width: 78px !important;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-tail,
  .ant-timeline.ant-timeline-label .ant-timeline-item-head {
    left: 90px !important;
  }
  .ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
    left: 86px !important;
    width: calc(100% - 92px) !important;
  }
`;

const RowHeliumData = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;
const RowTotalFilling = styled(RowHeliumData)`
  font-weight: bold;
`;
const TotalFillingAmount = styled.div`
  color: rgb(236, 102, 2);
`;
const HeliumDataIcon = styled.div`
  margin-right: 10px;
`;
const HeliumDataDescription = styled.div`
  display: flex;
`;
const PageBody = styled.div`
  padding: 8px;
  @media (min-width: 576px) {
    padding: 16px;
  }
`;
const DateValue = styled.div`
  color: rgba(0,0,0,.45);
`;

const BottomToolbar = styled.div`
  position: fixed;
  bottom: 10px;
  display:flex;
  width:100%;
  justify-content:center;
`;

interface LevelOrRefillingRow {
  type: 'Level' | 'Refilling';
  entity: Level | Refilling;
}

const SystemDetailPage = () => {
  let { systemId } = useParams<{ systemId?: string }>();

  const {
    mrSystem,
    mutate: mutateMrSystem,
    loading: isLoading,
    updateLevel,
    clearLevel,
  } = useMrSystem(systemId);
  const {
    refillings,
    loading: isRefillingsLoading,
    mutate: mutateRefillings,
    createRefilling,
    updateRefilling,
    deleteRefilling,
  } = useMrSystemRefillings(systemId);

  const totalRefilling = reduce(refillings, (prev, curr) => {
    return prev + curr.amount;
  }, 0);

  let rows: LevelOrRefillingRow[] = mrSystem ? mrSystem.levels.map(level => {
    return {
      type: 'Level',
      entity: level,
    }
  }) : [];
  
  if (refillings) {
    for (let index = 0; index < refillings.length; index++) {
      const refilling = refillings[index];
      const insertPosition = rows.findIndex(row => {
        if (row.type === 'Level') {
          const rowLevel = row.entity as Level;
          if (rowLevel.levelMeasureDate > refilling.refilledAt) return true;
        }
        return false;
      })

      if (insertPosition !== -1) {
        rows.splice(insertPosition, 0, { type: 'Refilling', entity: refilling });
      } else {
        rows.push({ type: 'Refilling', entity: refilling });
      }
    }
  }

  // update level
  const [isFormHeliumLevelNew, setIsFormHeliumLevelNew] = useState(false);
  const [formHeliumLevelModalVisible, setFormHeliumLevelModalVisible] = useState(false);
  const [formHeliumLevelInitialValues, setFormHeliumLevelInitialValues] = useState<HeliumLevelFormValues>({});

  const measureHeliumLevel = (level: Level) => {
    setIsFormHeliumLevelNew(false);
    setFormHeliumLevelInitialValues({
      levelId: level.levelId,
      amount: level.amount,
      date: level.levelMeasureDate ? moment(level.levelMeasureDate) : undefined
    })
    setFormHeliumLevelModalVisible(true);
  }
  const handleHeliumLevelModalClose = () => {
    setFormHeliumLevelModalVisible(false);
    mutateMrSystem();
  }

  // Form Refilling
  const [isRefillingFormNew, setIsRefillingFormNew] = useState(false);
  const [formRefillingModalVisible, setFormRefillingModalVisible] = useState(false);
  const [formRefillingInitialValues, setFormRefillingInitialValues] = useState<RefillingFormValues>({});

  const addRefilling = () => {
    setIsRefillingFormNew(true);
    setFormRefillingInitialValues({
      mrSystemId: systemId,
    });
    setFormRefillingModalVisible(true);
  }
  const handleEditRefilling = (record: Refilling): void => {
    const initialValues: RefillingFormValues = {
      refillingId: record.refillingId,
      mrSystemId: record.mrSystemId,
      amount: record.amount,
      refilledAt: moment(record.refilledAt),
      reason: record.reason,
      notes: record.notes,
    };
    setFormRefillingInitialValues(initialValues)
    setIsRefillingFormNew(false);
    setFormRefillingModalVisible(true);
  }
  const handleRefillingModalClose = () => {
    setFormRefillingModalVisible(false);
    mutateRefillings();
  }

  return (
    <>
      <GlobalStyle />
      {isLoading &&
        <PageHeader
          ghost={false}
        >
          <Skeleton active paragraph={{ rows: 2 }} />
        </PageHeader>
      }
      {mrSystem &&
        <>

          <PageHeader
            ghost={false}
            title={`System ${systemId}`}
          >
            <div>{mrSystem.country}</div>
            <div>{mrSystem.type}</div>
            <div>{mrSystem.site}</div>
          </PageHeader>
          <PageBody>
            <Spin spinning={isRefillingsLoading}>
              <Card>
                <RowTotalFilling>
                  <div>Total filling</div>
                  <TotalFillingAmount>{totalRefilling}L</TotalFillingAmount>
                </RowTotalFilling>
              </Card>
              {rows.map(item => {
                if (item.type === 'Level') {
                  const itemLevel = item.entity as Level;
                  return (
                    <Card key={itemLevel.levelId}>
                      <RowHeliumData
                        onClick={() => measureHeliumLevel(itemLevel)}
                      >
                        <HeliumDataDescription>
                          <HeliumDataIcon>
                            <img width="40" src="/images/gauge.png" alt="level" />
                          </HeliumDataIcon>
                          <div>
                            <div>{itemLevel.levelType}</div>
                            <DateValue>
                              {itemLevel.levelMeasureDate ? moment(itemLevel.levelMeasureDate).format('YYYY-MM-DD') : null}
                            </DateValue>
                          </div>
                        </HeliumDataDescription>
                        <div>
                          {itemLevel.amount ?
                            `${itemLevel.amount}${itemLevel.unit === 'Liter' ? 'L' : '%'}` :
                            '-'
                          }
                        </div>
                      </RowHeliumData>
                    </Card>
                  );
                } else {
                  const itemRefilling = item.entity as Refilling;
                  return (
                    <Card key={itemRefilling.refillingId}>
                      <RowHeliumData
                        onClick={() => handleEditRefilling(itemRefilling)}
                      >
                        <HeliumDataDescription>
                          <HeliumDataIcon>
                            <img width="40" src="/images/gas.png" alt="refilling" />
                          </HeliumDataIcon>
                          <div>
                            <div>{RefillingReason[itemRefilling.reason]} {itemRefilling.notes && `(${itemRefilling.notes})`}</div>
                            <DateValue>
                              {itemRefilling.refilledAt ? moment(itemRefilling.refilledAt).format('YYYY-MM-DD') : null}
                            </DateValue>
                          </div>
                        </HeliumDataDescription>
                        <div>
                          {itemRefilling.amount}L
                        </div>
                      </RowHeliumData>
                    </Card>
                  );
                }
              })}
              <Card>
                <RowTotalFilling>
                  <div>Total filling</div>
                  <TotalFillingAmount>{totalRefilling}L</TotalFillingAmount>
                </RowTotalFilling>
              </Card>
            </Spin>
          </PageBody>
          <BottomToolbar>
            <Button
              type="primary"
              onClick={addRefilling}
              shape="round"
            >
              <PlusOutlined /> Fill Helium
            </Button>
          </BottomToolbar>
          <FormRefilling
            visible={formRefillingModalVisible}
            onClose={handleRefillingModalClose}
            isNew={isRefillingFormNew}
            initialValues={formRefillingInitialValues}
            onCreateRefilling={createRefilling}
            onUpdateRefilling={updateRefilling}
            onDeleteRefilling={deleteRefilling}
          />
          <FormHeliumLevel
            visible={formHeliumLevelModalVisible}
            onSubmit={updateLevel}
            onCancel={handleHeliumLevelModalClose}
            onClear={clearLevel}
            onClose={handleHeliumLevelModalClose}
            isNew={isFormHeliumLevelNew}
            initialValues={formHeliumLevelInitialValues}
          />
        </>
      }
    </>
  );
};

export default SystemDetailPage;

