import { useState } from 'react';
import { DualAxes, Pie, Column, ColumnConfig, DualAxesConfig } from '@ant-design/charts';
import styled from "styled-components";
import { useMrSystemLevelDistribution, useRefillingsByMonth, useRefillingsByReason, useUser } from '../../lib/hooks/dashboard';
import { Select, DatePicker, Space, Table } from 'antd';
import moment, { Moment } from 'moment';
import orderBy from 'lodash/orderBy';
import round from 'lodash/round';
import { useAllRegions } from '../../lib/hooks/use-all-regions';
import { LevelDistributionMrSystem } from '../../models/Dtos';
import { ColumnsType } from 'antd/es/table';
import { Region } from '../../models/Entities';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { BlockLoading } from '../../components/Loading';
const { RangePicker } = DatePicker;

const ChartContainer = styled.div`
  padding: 40px;
`;

const DashboardContainer = styled.div`
  overflow: hidden;
  padding: 8px;
  background-color: white;

  @media (min-width: 768px) {
    padding: 16px;
  }
  @media (min-width: 1200px) {
    padding: 24px;
  }
`;

const Filter = styled.div`
  margin-bottom: 20px;
`;

const COLOR_PLATE_10 = [
  '#5B8FF9',
  '#5AD8A6',
  '#5D7092',
  '#F6BD16',
  '#E8684A',
  '#6DC8EC',
  '#9270CA',
  '#FF9D4D',
  '#269A99',
  '#FF99C3',
];

interface LevelItem {
  mrSystemId: string,
  amountLiter: number | null,
  amountPercent: number | null,
  levelType: string,
};

const Dashboard = () => {
  const [regionId, setRegionId] = useState<string | undefined>(undefined);
  const [deliveredAtRange, setDeliveredAtRange] = useState<[Moment | null, Moment | null]>([null, null]);

  const { data: mrSystemLevelDistribution, loading: isLevelDistributionLoading } =
    useMrSystemLevelDistribution({
      regionId,
      deliveredAtStart: deliveredAtRange[0] ? deliveredAtRange[0].format('YYYY-MM-DD') : null,
      deliveredAtEnd: deliveredAtRange[1] ? deliveredAtRange[1].format('YYYY-MM-DD') : null,
    });

  const { data: refillingsByReason, loading: isRefillingsByReasonLoading } =
    useRefillingsByReason({
      regionId,
      deliveredAtStart: deliveredAtRange[0] ? deliveredAtRange[0].format('YYYY-MM-DD') : null,
      deliveredAtEnd: deliveredAtRange[1] ? deliveredAtRange[1].format('YYYY-MM-DD') : null,
    });

  const { data: refillingsByMonth, loading: isRefillingsByMonthLoading } =
    useRefillingsByMonth({
      regionId,
      deliveredAtStart: deliveredAtRange[0] ? deliveredAtRange[0].format('YYYY-MM-DD') : null,
      deliveredAtEnd: deliveredAtRange[1] ? deliveredAtRange[1].format('YYYY-MM-DD') : null,
    });

  const onRegionChange = (id: string) => {
    setRegionId(id);
    setDeliveredAtRange([null, null]);
  };

  // construct levels data and refilling by month data
  const levelData: LevelItem[] = [];
  const totalRefillingPerSystemByL3Month: { [key: string]: number[] } = {};
  if (mrSystemLevelDistribution) {
    mrSystemLevelDistribution.mrSystems.forEach(system => {
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l1.amountLiter,
        amountPercent: system.l1.amountPercent,
        levelType: system.l1.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l1_1.amountLiter,
        amountPercent: system.l1_1.amountPercent,
        levelType: system.l1_1.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l1_2.amountLiter,
        amountPercent: system.l1_2.amountPercent,
        levelType: system.l1_2.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l1_3.amountLiter,
        amountPercent: system.l1_3.amountPercent,
        levelType: system.l1_3.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l1_4.amountLiter,
        amountPercent: system.l1_4.amountPercent,
        levelType: system.l1_4.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l2.amountLiter,
        amountPercent: system.l2.amountPercent,
        levelType: system.l2.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l3.amountLiter,
        amountPercent: system.l3.amountPercent,
        levelType: system.l3.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l4.amountLiter,
        amountPercent: system.l4.amountPercent,
        levelType: system.l4.levelType,
      });
      levelData.push({
        mrSystemId: system.mrSystemId,
        amountLiter: system.l5.amountLiter,
        amountPercent: system.l5.amountPercent,
        levelType: system.l5.levelType,
      });

      // totalrefilling
      const month = system.l3.levelMeasureDate ? moment(system.l3.levelMeasureDate).format('YYYY-MM') : null;
      if (month !== null) {
        if (!totalRefillingPerSystemByL3Month[month]) {
          totalRefillingPerSystemByL3Month[month] = [];
        }
        totalRefillingPerSystemByL3Month[month].push(system.totalRefilling)
      }
    });
  }

  var totalRefillings = Object.keys(totalRefillingPerSystemByL3Month).map(key => {
    return {
      month: key,
      averageRefilling: round(totalRefillingPerSystemByL3Month[key].reduce((prev, current) => prev + current) / totalRefillingPerSystemByL3Month[key].length, 2)
    }
  });
  totalRefillings = orderBy(totalRefillings, ['month']);

  const mrSystemData = mrSystemLevelDistribution ? mrSystemLevelDistribution.mrSystems : []

  const config: DualAxesConfig = {
    data: [mrSystemData, levelData],
    xField: 'mrSystemId',
    yField: ['totalRefilling', 'amountLiter'],
    meta: {
      totalRefilling: { sync: 'amountLiter' },
      amountLiter: { sync: true },
    },
    yAxis: {
      amountLiter: false,
    },
    geometryOptions: [
      {
        geometry: 'column',
        columnWidthRatio: 0.4,
        color: '#666',
        columnStyle: {
          fillOpacity: 0.3,
        },
        // tooltip: {
        //   fields: [
        //     'deliveredAt',
        //     'totalRefilling',
        //     'refillingReason',
        //   ]
        // },
      },
      {
        geometry: 'line',
        seriesField: 'levelType',
        // yAxis: {
        //   label: {
        //     formatter: function formatter(v) {
        //       return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
        //         return ''.concat(s, ',');
        //       });
        //     },
        //   },
        // },
        color: COLOR_PLATE_10,
        lineStyle: {
          lineWidth: 0
        },
        point: {
          size: 5,
          shape: 'circle',
        },
      },
    ],
  };

  const configRefillingsPerSystemByL3Month: ColumnConfig = {
    data: totalRefillings,
    xField: 'month',
    yField: 'averageRefilling',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  const disabledDate = (current: Moment) => {
    if (!mrSystemLevelDistribution) {
      return false;
    }
    const min = moment(mrSystemLevelDistribution.deliveredAtMin);
    const max = moment(mrSystemLevelDistribution.deliveredAtMax);

    const tooEarly = min && min.isAfter(current);
    const tooLate = max && max.add(1, 'days').isBefore(current);

    return tooEarly || tooLate;
  };

  let regions: Region[] | null | undefined = null;
  const { user: currentUser } = useUser();
  const { data: allRegions } = useAllRegions();

  if (currentUser) {
    if (currentUser.roles.includes('Administrator')) {
      regions = allRegions;
    } else {
      regions = currentUser.regionRoles.map(regionRole => ({
        regionId: regionRole.regionId,
        name: regionRole.regionName
      }));
    }
    if (!regionId && regions && regions.length > 0) {
      setRegionId(regions[0].regionId);
    }
  }

  const columns: ColumnsType<LevelDistributionMrSystem> = [
    {
      title: 'Serial',
      width: 82,
      dataIndex: 'mrSystemId',
      key: 'serial',
      fixed: 'left',
    },
    {
      title: 'System',
      width: 139,
      dataIndex: 'mrSystemType',
      key: 'mrSystemType',
      fixed: 'left',
    },
    {
      title: 'Delivery Date',
      width: 110,
      dataIndex: 'deliveredAt',
      render: deliveredAt => deliveredAt ? moment(deliveredAt).format('YYYY-MM-DD') : "",
      key: 'deliveredAt',
      defaultSortOrder: 'descend',
      sorter: (a, b) => {
        if (a.deliveredAt && b.deliveredAt) return moment(a.deliveredAt).diff(moment(b.deliveredAt), 'days');
        if (a.deliveredAt === null && b.deliveredAt === null) return 0;
        if (a.deliveredAt === null) return 1;
        if (b.deliveredAt === null) return -1;
        return 0;
      },
    },
    {
      title: 'L1',
      width: 85,
      dataIndex: ['l1', 'amountLiter'],
      key: 'l1'
    },
    {
      title: 'L1-L1.1 Days',
      width: 80,
      render: (text, record) => {
        if (record.l1 && record.l1.levelMeasureDate && record.l1_1 && record.l1_1.levelMeasureDate) {
          return moment(record.l1_1.levelMeasureDate).diff(moment(record.l1.levelMeasureDate), 'days');
        }
        return "";
      },
      key: 'Days_L1L1.1',
    },
    {
      title: 'L1.1',
      width: 85,
      dataIndex: ['l1_1', 'amountLiter'],
      key: 'l1.1'
    },
    {
      title: 'L1.2',
      width: 85,
      dataIndex: ['l1_2', 'amountLiter'],
      key: 'l1.2'
    },
    {
      title: 'L1.3',
      width: 85,
      dataIndex: ['l1_3', 'amountLiter'],
      key: 'l1.3'
    },
    {
      title: 'L1.4',
      width: 85,
      dataIndex: ['l1_4', 'amountLiter'],
      key: 'l1.4'
    },
    {
      title: 'L2',
      width: 85,
      dataIndex: ['l2', 'amountLiter'],
      key: 'l2'
    },
    {
      title: 'L2-L3 Days',
      width: 80,
      render: (text, record) => {
        if (record.l2 && record.l2.levelMeasureDate && record.l3 && record.l3.levelMeasureDate) {
          return moment(record.l3.levelMeasureDate).diff(moment(record.l2.levelMeasureDate), 'days');
        }
        return "";
      },
      key: 'Days_L2L3',
    },
    {
      title: 'L3',
      width: 85,
      dataIndex: ['l3', 'amountLiter'],
      key: 'l3'
    },
    {
      title: 'L4',
      width: 85,
      dataIndex: ['l4', 'amountLiter'],
      key: 'l4'
    },
    {
      title: 'L5',
      width: 85,
      dataIndex: ['l5', 'amountLiter'],
      key: 'l5'
    },
    {
      title: 'Total Refill (L)',
      width: 85,
      dataIndex: 'totalRefilling',
      key: 'totalRefilling',
    },
    {
      title: 'Refilling Reason',
      width: 110,
      dataIndex: 'refillingReason',
      key: 'refillingReason',
    },
    {
      title: 'L1 Date',
      width: 110,
      dataIndex: ['l1', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l1_date',
    },
    {
      title: 'L1.1 Date',
      width: 110,
      dataIndex: ['l1_1', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l1.1_date',
    },
    {
      title: 'L1.2 Date',
      width: 110,
      dataIndex: ['l1_2', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l1.2_date',
    },
    {
      title: 'L1.3 Date',
      width: 110,
      dataIndex: ['l1_3', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l1.3_date',
    },
    {
      title: 'L1.4 Date',
      width: 110,
      dataIndex: ['l1_4', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l1.4_date',
    },
    {
      title: 'L2 Date',
      width: 110,
      dataIndex: ['l2', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l2_date',
    },
    {
      title: 'L3 Date',
      width: 110,
      dataIndex: ['l3', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l3_date',
    },
    {
      title: 'L4 Date',
      width: 110,
      dataIndex: ['l4', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l4_date',
    },
    {
      title: 'L5 Date',
      width: 110,
      dataIndex: ['l5', 'levelMeasureDate'],
      render: date => date ? moment(date).format('YYYY-MM-DD') : "",
      key: 'l5_date',
    }
  ];

  var configRefillingsByReason = {
    appendPadding: 10,
    data: refillingsByReason,
    angleField: 'total',
    colorField: 'reason',
    radius: 0.8,
    label: {
      type: 'outer',
      content: '{name} {percentage}',
    },
    interactions: [{ type: 'pie-legend-active' }, { type: 'element-active' }],
  };

  const configRefillingsByMonth: ColumnConfig = {
    data: refillingsByMonth,
    xField: 'month',
    yField: 'total',
    label: {
      position: 'middle',
      style: {
        fill: '#FFFFFF',
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
  };

  return (
    <DashboardContainer>
      <Filter>
        <Space>
          {regions &&
            <Select
              placeholder="Region"
              options={regions.map(region => ({ label: region.name, value: region.regionId }))}
              optionFilterProp="label"
              value={regionId}
              onChange={onRegionChange}
            >
            </Select>
          }
          <RangePicker
            value={[
              mrSystemLevelDistribution ? moment(mrSystemLevelDistribution.deliveredAtStart) : deliveredAtRange[0],
              mrSystemLevelDistribution ? moment(mrSystemLevelDistribution.deliveredAtEnd) : deliveredAtRange[1],
            ]}
            disabledDate={disabledDate}
            onChange={dates => setDeliveredAtRange([dates ? dates[0]: null, dates ? dates[1] : null])}
          />
        </Space>
      </Filter>
      
      {(isLevelDistributionLoading || isRefillingsByMonthLoading || isRefillingsByReasonLoading) &&
        <BlockLoading />
      }
      
      {mrSystemLevelDistribution &&
        <div>
          <ChartContainer>
            <h2>Refillings by reason</h2>
            {refillingsByReason &&
              <Pie {...configRefillingsByReason} />
            }
          </ChartContainer>

          <ChartContainer>
            <h2>Average total refillings per system</h2>
            {totalRefillings &&
              <Column {...configRefillingsPerSystemByL3Month} />
            }
          </ChartContainer>

          <ChartContainer>
            <h2>Total refillings by month</h2>
            {refillingsByMonth &&
              <Column {...configRefillingsByMonth} />
            }
          </ChartContainer>

          <ChartContainer>
            <h2>System helium levels</h2>
            <DualAxes
              {...config}
            />
          </ChartContainer>

          <div style={{ height: 20 }}></div>
          <Table<LevelDistributionMrSystem>
            rowKey="mrSystemId"
            columns={columns}
            dataSource={mrSystemLevelDistribution.mrSystems}
            scroll={{ x: 1300, y: 400 }}
            pagination={{ pageSize: 100 }}
          />
        </div>
      }
    </DashboardContainer>

  )
};

const DashboardPage = withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <BlockLoading />,
});

export default DashboardPage;